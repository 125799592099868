<template>
  <div class="home">
    <header>{{ this.$application_name }}</header>
    <div class="content">
      <router-view></router-view>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" @click="tohome">首页</van-tabbar-item>
      <!-- <van-tabbar-item icon="scan" @click="toHomeScan"
        >扫码开票</van-tabbar-item
      > -->
      <van-tabbar-item icon="credit-pay" @click="tomybill"
        >发票夹</van-tabbar-item
      >
      <van-tabbar-item icon="coupon-o" @click="tobillhead"
        >发票名片</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
import { BrowserMultiFormatReader } from '@zxing/library'
import Event from '../util/event.js'
export default {
  data() {
    return {
      active: 0
    }
  },
  mounted() {
    let that = this
    Event.$on('creatBillHead', function () {
      that.active = 2
    })
    Event.$on('creatMyBill', function () {
      that.active = 1
    })
  },
  methods: {
    tohome() {
      this.$router.push('/')
    },
    tomybill() {
      this.$router.push('/mybill')
    },
    tobillhead() {
      this.$router.push('/billhead')
    }
  }
}
</script>

<style>
.home header {
  height: 50px;
  line-height: 50px;
  color: #000;
  font-size: 18px;
  padding-left: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.home .content {
  margin: 50px 0 80px 0;
}

.home .van-tabbar--fixed {
  height: 80px;
  border-top: 1px solid #eee;
}
.home .el-dialog {
  width: 100%;
  height: 80%;
}

.home .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
