import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect:'/index',
    children:[
      {
        path:'/index',
        name:'Homepage',
        component:()=>import("../views/HomePage.vue"),
        meta:{
          keepAlive:false
        }
      },
      {
        path: '/billdetail',
        name: 'Billdetail',
        component: () => import('../views/BillDetail.vue'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: '/mybill',
        name: 'Mybill',
        component: () => import('../views/MyBill.vue'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: '/billhead',
        name: 'Billhead',
        component: () => import('../views/BillHead.vue'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: '/makeinvoice',
        name: 'Makeinvoice',
        component: () => import('../views/MakeInvoice.vue'),
        meta:{
          keepAlive:false
        }
      },
      
      {
        path: '/updatainvoice',
        name: 'Updatainvoice',
        component: () => import('../views/UpdataInvoice.vue'),
        meta:{
          keepAlive:false
        }
      }
    ]
  },
  
]

const router = new VueRouter({
  routes
})

export default router
