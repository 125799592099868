import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios' 
import { Form, Field, CellGroup,Button ,Icon,RadioGroup, Radio,Tab, Tabs,Dialog,Overlay,Popup ,ImagePreview, Empty,Tabbar, TabbarItem ,Tag,Image as VanImage} from 'vant'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(Form)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Button)
Vue.use(Icon)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Dialog)
Vue.use(Overlay)
Vue.use(Popup)
Vue.use( Empty)
Vue.use( Tabbar)
Vue.use( TabbarItem )
Vue.use( Tag )
Vue.use( VanImage )
Vue.use(ElementUI);

Vue.prototype.ImagePreview = ImagePreview
Vue.prototype.$axios = axios

Vue.prototype.$application_name = application_name
document.title = application_name

// axios.defaults.baseURL = 'http://cloud.k-teamwork.com:15050/'
axios.defaults.baseURL = 'invoice/'

new Vue({
  router,
  
  render: h => h(App)
}).$mount('#app')
